.button {
  position: absolute;
  bottom: 90px;
  left: 1rem;
  background-color: #e40016;
  padding: 1rem;
  border-radius: 60%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .button {
    left: 5vw;
    transform: scale(1.4);
    bottom: 110px;
  }
}