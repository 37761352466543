.container {
  width: 100vw;
  height: 100vh;
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  margin: 2rem 4rem;

  .attraction {
    width: 50%;
  }
  
  .feedback {
    width: 50%;
  }
}

@media only screen and (max-width: 720px) {
  .flexcontainer {
    flex-direction: column;
    margin: 1rem 1rem;

    .attraction {
      width: 100%;
    }
    
    .feedback {
      width: 100%;
    }
  }
}