body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ErrorView_text__1G-nf {
  padding: 0rem .8rem 0rem .8rem;
  font-size: 1.0rem;
  line-height: 1.5rem;
  letter-spacing: 0.12rem;
  word-spacing: 0.16rem; }

.FeedbackFields_container__sHKOE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
  background-color: #d3d3d3;
  margin-top: 1rem;
  box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1); }
  .FeedbackFields_container__sHKOE p {
    font-size: 1rem;
    color: #151515;
    line-height: 1.5rem; }
  .FeedbackFields_container__sHKOE .FeedbackFields_field__2ftrY {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .FeedbackFields_container__sHKOE label {
    margin: .7rem 0 .2rem 0;
    font-size: 1rem;
    color: #151515;
    line-height: 1.5rem; }
  .FeedbackFields_container__sHKOE input {
    height: 1.5rem; }
  .FeedbackFields_container__sHKOE button {
    margin: 1rem 0 .2rem 0;
    width: 40%;
    height: 2rem;
    cursor: pointer; }

@media only screen and (min-width: 960px) {
  .FeedbackFields_container__sHKOE {
    max-width: 30rem; } }

.FeedbackForm_container__JNScz {
  padding: .8rem; }
  .FeedbackForm_container__JNScz .FeedbackForm_button__3L7k3 {
    font-size: 1.0rem;
    line-height: 1.5rem; }

.AttractionDetail_container__27qBS {
  padding: .8rem .8rem .2rem .8rem; }
  .AttractionDetail_container__27qBS .AttractionDetail_header__45udU {
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin: 0; }
  .AttractionDetail_container__27qBS .AttractionDetail_description__1W3q4 {
    font-size: 1.0rem;
    line-height: 1.5rem; }

.YouTubePlayer_container__jJemv {
  width: 100vw;
  height: auto;
  background-color: #000; }

.AttractionView_container__3mPUG {
  width: 100vw;
  height: 100vh; }

.AttractionView_flexcontainer__1L-Iz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 2rem 4rem; }
  .AttractionView_flexcontainer__1L-Iz .AttractionView_attraction__1GW7v {
    width: 50%; }
  .AttractionView_flexcontainer__1L-Iz .AttractionView_feedback__2BAck {
    width: 50%; }

@media only screen and (max-width: 720px) {
  .AttractionView_flexcontainer__1L-Iz {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 1rem 1rem; }
    .AttractionView_flexcontainer__1L-Iz .AttractionView_attraction__1GW7v {
      width: 100%; }
    .AttractionView_flexcontainer__1L-Iz .AttractionView_feedback__2BAck {
      width: 100%; } }

.QRButton_button__cFkCc {
  position: absolute;
  bottom: 90px;
  right: 1rem;
  background-color: #e40016;
  padding: 1rem;
  border-radius: 60%;
  width: 2rem;
  height: 2rem; }

@media only screen and (min-width: 992px) {
  .QRButton_button__cFkCc {
    right: 5vw;
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    bottom: 110px; } }

.UserLocationButton_button__2xYxm {
  position: absolute;
  bottom: 90px;
  left: 1rem;
  background-color: #e40016;
  padding: 1rem;
  border-radius: 60%;
  width: 2rem;
  height: 2rem;
  cursor: pointer; }

@media only screen and (min-width: 992px) {
  .UserLocationButton_button__2xYxm {
    left: 5vw;
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    bottom: 110px; } }

.SwitchViewButton_button__2V-fr {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  width: 2rem;
  height: 2rem; }

@media only screen and (min-width: 992px) {
  .SwitchViewButton_button__2V-fr {
    right: 5vw;
    top: 3vw;
    -webkit-transform: scale(1.4);
            transform: scale(1.4); } }

.SwitchViewButton_closeButton__3D00I {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1rem;
  width: 2rem;
  height: 2rem; }

@media only screen and (min-width: 992px) {
  .SwitchViewButton_closeButton__3D00I {
    right: 5vw;
    top: 3vw;
    -webkit-transform: scale(1.4);
            transform: scale(1.4); } }

.btn {
  position: absolute;
  bottom: 50px;
  right: 50px; }

.icon {
  color: '#ffffff'; }

.AttractionListItem_container__2QX8l {
  padding: 0rem .8rem 0rem .8rem; }
  .AttractionListItem_container__2QX8l .AttractionListItem_text__3OJ2L {
    font-size: 1.0rem; }

.AttractionList_container__3ylTU {
  padding: .8rem .8rem .2rem .8rem; }
  .AttractionList_container__3ylTU .AttractionList_placeName__Dsg9m {
    font-size: 1.4rem;
    margin: 0; }

.PlaceListView_container__2dsOa {
  width: 100vw;
  height: 100vh; }

.PlaceListView_flexcontainer__j_T6M {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 2rem 4rem; }
  .PlaceListView_flexcontainer__j_T6M .PlaceListView_attraction__6XFiO {
    width: 50%; }
  .PlaceListView_flexcontainer__j_T6M .PlaceListView_feedback__jxgd- {
    width: 50%; }

.InfoView_container__2fNmD {
  width: 100vw;
  height: 80vh; }

.InfoView_textContainer__3G9TX {
  width: 50vw;
  margin: 4rem auto;
  font-size: 1.0rem;
  line-height: 1.5rem;
  color: #151515; }
  .InfoView_textContainer__3G9TX h2 {
    font-size: 1.4rem;
    line-height: 2.1rem; }

@media only screen and (max-width: 720px) {
  .InfoView_textContainer__3G9TX {
    width: auto;
    margin: 3rem; } }

.FooterView_container__28TRc {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 50px;
  box-sizing: border-box;
  background-color: #d3d3d3;
  margin: 0; }
  .FooterView_container__28TRc .FooterView_linkContainer__2vt-B {
    text-align: center;
    padding: auto; }

