.container {
  padding: .8rem .8rem .2rem .8rem;
  
  .header {
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin: 0;
  }

  .description {
    font-size: 1.0rem;
    line-height: 1.5rem;
  }
}