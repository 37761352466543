.container {
  width: 100vw;
  height: 80vh;
}

.textContainer {
  width: 50vw;
  margin: 4rem auto;
  font-size: 1.0rem;
  line-height: 1.5rem;
  color: #151515;

  h2 {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}


@media only screen and (max-width: 720px) {
  .textContainer {
    width: auto;
    margin: 3rem;
  }
}