.button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255,255,255,0.5);
  padding: 1rem;
  width: 2rem;
  height: 2rem;
}

@media only screen and (min-width: 992px) {
  .button {
    right: 5vw;
    top: 3vw;
    transform: scale(1.4);
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
}

@media only screen and (min-width: 992px) {
  .closeButton {
    right: 5vw;
    top: 3vw;
    transform: scale(1.4);
  }
}