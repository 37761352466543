.container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 50px;
  box-sizing: border-box;
  background-color: #d3d3d3;
  margin: 0;

  .linkContainer {
    text-align: center;
    padding: auto;
  }
}