.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #d3d3d3;
  margin-top: 1rem;
  box-shadow: .1rem .2rem .2rem rgba(0, 0, 0, .1);

  p {
    font-size: 1rem;
    color: #151515;
    line-height: 1.5rem;
  }

  .field {
    display: flex;
    flex-direction: column;
  }

  label {
    margin: .7rem 0 .2rem 0;
    font-size: 1rem;
    color: #151515;
    line-height: 1.5rem;
  }
  
  input {
    height: 1.5rem;
  }

  button {
    margin: 1rem 0 .2rem 0;
    width: 40%;
    height: 2rem;
    cursor: pointer;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 30rem;
  }
}
