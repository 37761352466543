.container {
  width: 100vw;
  height: 100vh;
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  margin: 2rem 4rem;

  .attraction {
    width: 50%;
  }
  
  .feedback {
    width: 50%;
  }
}